<template>
  <CToaster placement="top-end">
    <template v-for="(toast, index) in this.$store.state.toasts">
      <CToast :color="toast.color" class="border-4" v-if="toast.closed == false" @close="markToastClosed(toast)" v-bind:key="index">
        <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{toast.title}}</span>
        <small>7 min ago</small>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>  
      </CToast>
    </template>
    <!-- <CToast :color="'success'" class="border-4" :autohide="false">
        <CToastHeader closeButton>
        <span class="me-auto fw-bold">Title</span>
        <small>7 min ago</small>
        </CToastHeader>
        <CToastBody>
          content
        </CToastBody>  
      </CToast> -->
  </CToaster>

  <router-view />
</template>
<script>
import { watch } from 'vue'
import { useStore } from 'vuex'
import EventBus from "./common/EventBus";

export default {
  setup() {
    const store = useStore()

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  },
  methods: {
    logOut() {
      console.log("logout")
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    markToastClosed(toast) {
      toast.closed = true;
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeDestroy() {
    EventBus.remove("logout");
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
