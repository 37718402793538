import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import Vuex from 'vuex';
import setupInterceptors from './services/setupInterceptors';
import fontAwesome from './services/font-awesome';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import axios from 'axios'
import VueAxios from 'vue-axios'

setupInterceptors(store);
fontAwesome();

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Vuex)
app.use(VueAxios, axios)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
router.app = app