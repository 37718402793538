import axiosInstance from "./api";
import TokenService from "./token.service";
import router from '../router';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        config.headers["x-access-token"] = token; // for Node.js Express back-end
        // config.headers["X-CSRF-TOKEN"] = token; // for cookies
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const COOKIE_EXPIRED_MSG = 'Token has expired'
  axiosInstance.interceptors.response.use((response) => {
        return response
  }, async (error) => {
    // var error_message = null;
    // if (error.response)
    //   error_message = error.response.data.msg
    if (error.response) {
      switch (error.response.status) {
        case 401:
          router.push('/pages/login');
          break;
        case 404:
          router.push('/pages/404');
          break;
        default:
          break;
      }
    }
    return error.response;
  });
}

export default setup;