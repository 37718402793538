import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login({ username, password }) {
    console.log("login");
    return api
      .post("/api/auth/signin", {
        username: username,
        password: password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      }).catch(e => {
        console.log(e);
        console.log(e);
      })
  }

  logout() {
    TokenService.removeUser();
  }

  // register({ username, email, password }) {
  //   return api.post("/api/auth/signup", {
  //     username,
  //     email,
  //     password
  //   });
  // }

  refreshToken({ commit }, accessToken) {
    commit('refreshToken', accessToken);
  }
}

export default new AuthService();