import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { 
    faSync,
    faBug,
    faComment,
    faComments,
    faCogs,
    faCog, 
    faDonate, 
    faCheckCircle, 
    faCheck, 
    faMonument, 
    faInfoCircle, 
    faHistory, 
    faBan, 
    faEnvelope, 
    faEnvelopeOpen, 
    faRedoAlt, 
    faRedo, 
    faCalendar, 
    faCalendarCheck, 
    faCalendarTimes, 
    faBell, 
    faStop, 
    faStopCircle, 
    faStopwatch, 
    faClock, 
    faTrashAlt, 
    faEye, 
    faTools, 
    faQuestionCircle, 
    faStar, 
    faCircle, 
    faCaretLeft, 
    faCaretRight, 
    faCaretUp, 
    faCaretDown, 
    faExclamationTriangle, 
    faExclamationCircle, 
    faPlay, 
    faPause, 
    faTruckCouch,
    faPersonCarry,
    faHome,
    faUser,
    faTimes,
    faExpand,
    faUserEdit,
    faEdit,
    faSpinner,
    faCut,
    faCopy,
    faBolt,
    faBurn,
    faJug,
    faRouter,
    faTv,
    faPhone,
    faTint,
    faRaindrops,
    faBuilding,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faChevronRight,
    faCircleNotch,
    faHouseUser,
    faPlusCircle,
    faMinusCircle,
    faExternalLink,
    faCode,
    faUpload,
    faDownload,
    faRemoveFormat,
    faSearch,
    faSearchMinus,
    faSearchPlus,
    faLock,
    faLockOpen,
    faDotCircle,
    faClipboard,
    faList,
    faLanguage,
    faBullhorn,
    faKeyboard,
    faPaperPlane,
    faRetweet,
    faShareAlt,
    faUserTie,
    faBook,
    faFile,
    faSolarPanel,
    faAlignJustify,
    faAlignSlash,
    faMap,
    faMagnifyingGlass,
    faMeterFire,
    faMeterBolt,
    faMeterDroplet,
    faHouseLaptop,
    faHeadset
  } from '@fortawesome/pro-solid-svg-icons'

const setup = () => {
        
    library.add(faJug)
    library.add(faSync)
    library.add(faBullhorn)
    library.add(faExternalLink)
    library.add(faBug)
    library.add(faComment)
    library.add(faComments)
    library.add(faDonate)
    library.add(faCheckCircle)
    library.add(faInfoCircle)
    library.add(faHistory)
    library.add(faBan)
    library.add(faEnvelope)
    library.add(faEnvelopeOpen)
    library.add(faRedoAlt)
    library.add(faRedo)
    library.add(faCalendar)
    library.add(faCalendarCheck)
    library.add(faCalendarTimes)
    library.add(faBell)
    library.add(faStop)
    library.add(faStopCircle)
    library.add(faStopwatch)
    library.add(faClock)
    library.add(faTrashAlt)
    library.add(faCogs)
    library.add(faCog)
    library.add(faCheck)
    library.add(faEye)
    library.add(faUser)
    library.add(faUserEdit)
    library.add(faEdit)
    library.add(faQuestionCircle)
    library.add(faTools)
    library.add(faStar)
    library.add(faCircle)
    library.add(faCaretLeft)
    library.add(faCaretRight)
    library.add(faCaretUp)
    library.add(faCaretDown)
    library.add(faExclamationTriangle)
    library.add(faExclamationCircle)
    library.add(faPlay)
    library.add(faPause)
    library.add(faTruckCouch)
    library.add(faPersonCarry)
    library.add(faHome)
    library.add(faTimes)
    library.add(faExpand)
    library.add(faSearch)
    library.add(faSearchMinus)
    library.add(faSearchPlus)
    library.add(faLock)
    library.add(faLockOpen)
    library.add(faDotCircle)
    library.add(faSpinner)
    library.add(faCut)
    library.add(faCopy)
    library.add(faBurn)
    library.add(faRouter)
    library.add(faBolt)
    library.add(faTint)
    library.add(faPhone)
    library.add(faTv)
    library.add(faBuilding)
    library.add(faArrowUp)
    library.add(faArrowDown)
    library.add(faArrowLeft)
    library.add(faArrowRight)
    library.add(faChevronRight)
    library.add(faCircleNotch)
    library.add(faHouseUser)
    library.add(faPlusCircle)
    library.add(faMinusCircle)
    library.add(faCode)
    library.add(faUpload)
    library.add(faDownload)
    library.add(faRaindrops)
    library.add(faRemoveFormat)
    library.add(faShareAlt)
    library.add(faLanguage)
    library.add(faClipboard)
    library.add(faList)
    library.add(faKeyboard)
    library.add(faPaperPlane)
    library.add(faRetweet)
    library.add(faUserTie)
    library.add(faHeadset)
    library.add(faBook)
    library.add(faFile)
    library.add(faSolarPanel)
    library.add(faAlignJustify)
    library.add(faAlignSlash)
    library.add(faMap)
    library.add(faMagnifyingGlass)
    library.add(faMeterFire)
    library.add(faMeterBolt)
    library.add(faMeterDroplet)
    library.add(faHouseLaptop)
    
    dom.watch()
};

export default setup;
