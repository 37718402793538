import axios from "axios";

const API_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  xsrfCookieName: 'csrf_access_token',
});

export default instance;