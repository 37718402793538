import { createStore } from 'vuex'
import { auth } from "./auth.module";

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'light',
    toasts: [],
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    appendToast(state, payload) {
      state.toasts.push(
        {
          title: payload.title,
          content: payload.content,
          color: payload.color,
          closed: false,
        }
      )
    }
  },
  actions: {},
  modules: {
    auth: auth,
  },
})
