import { inRole } from '@/common/roles';
import axios from 'axios';
import authHeader from './auth-header';
import api from "./api";
import { requiredUnless } from '@vuelidate/validators';

const USER_API_URL = `${process.env.VUE_APP_API_BASE_URL}/api`;

class UserService {
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  hasRole(role) {
    return this.getCurrentUser().scopes.includes(role);
  }

  intersectRoles(roles) {
    var result = false;
    roles.forEach(role => {
      if (this.getCurrentUser().scopes.includes(role)){
        result = true;
      }
    });
    return result;
  }

  users() {
    return api.get(`${USER_API_URL}/datafind/users`);
  }

  user(id) {
    return api.get(`${USER_API_URL}/datafind/user/${id}`);
  }

  updateUser({ user, updatePassword }) {
    return api.put(`${USER_API_URL}/datafind/user`, {
      user: user,
      updatePassword: updatePassword
    });
  }

  createUser({ user, updatePassword }) {
    return api.post(`${USER_API_URL}/datafind/user`, {
      user: user,
      updatePassword: updatePassword
    });
  }

  getInitials() {
    var user = this.getCurrentUser();
    return user.name.split(" ").map(x => x[0]).join(" ");
  }

}

export default new UserService();